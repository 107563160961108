import React from 'react';
import Helmet from 'react-helmet';
import useSetting from '@src/hooks/useSetting';

export default function ChatImbox() {
	const getSetting = useSetting();
	const imboxSid = getSetting('imbox_chat_sid');

	return imboxSid ? (
		<Helmet>
			{`<!-- ImBox -->`}
			<script type="text/javascript" id="imbox" async>
				{`var _sid = '${imboxSid}';
					(function() {
						var se = document.createElement('script');
						se.type = 'text/javascript';
						se.async = true;
						se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js';
						var s = document.getElementsByTagName('script')[0];
						s.parentNode.insertBefore(se, s);
					})();`}
			</script>
			{`<!-- //ImBox Script -->`}
		</Helmet>
	) : (
		<></>
	);
}
